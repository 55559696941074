// 文章推荐-非答疑
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Card, Col } from 'react-bootstrap'

const RecommandArticles2 = ({ currentArticleSlug, currentArticleTag }) => {
    const data = useStaticQuery(graphql`
        {
            allStrapiArticles(sort: {fields: updated_at, order: DESC}, filter: {articlesTag: {ne: "答疑"}, recomment: {eq: "recomment"}}, limit: 4) {
                edges {
                    node {
                        strapiId
                        title
                        slug
                        recomment
                        articlesTag
                        created_at
                        updated_at
                        content
                        image {
                            childImageSharp {
                                fixed(width: 800, height: 400, quality: 80) {
                                    base64
                                }
                            }
                        }
                        description
                    }
                }
            }
        }`
    )

    // 获取所有文章数据
    const articles = data.allStrapiArticles.edges.map(edge => edge.node);

    // 过滤掉当前文章
    const dtData = articles.filter(article => article.slug !== currentArticleSlug).slice(0, 3);

    if (dtData.length) {
        return (
            <Col xs={3} style={{ marginTop: '6rem' }}>
                <div className="dt-title">
                    <div>{currentArticleTag == '软文' ? '行业动态': '答疑'}</div>
                    <Link to="/industryDynamics" className="dt-more" style={{ textDecoration: 'none' }}>更多</Link>
                </div>
                {
                    dtData?.length ? dtData.map(item => {
                        const image = item.image?.childImageSharp?.fixed?.base64;

                        return (
                            <Link to={item.articlesTag == '公开课' ? `${item.content}` : `/article/${item.slug}`} target={item.articlesTag == '公开课' ? `_black` : '_self'} className="article-link" style={{ textDecoration: 'none' }}>
                                <Card className="dt-card">
                                    {/* { item?.image?.url ? <img className="dt-img" src={`${apiURL}${item.image.url}`} alt="" /> : null} */}
                                    {/* {
                        item?.image?.childImageSharp?.fixed?.base64 ? 
                        <Img className="dt-img" src={item.image.childImageSharp.fixed.base64} /> : null
                      } */}
                                    {image ? <img src={image} className="dt-img" /> : null}
                                    <Card.Body>
                                        <Card.Title className="dt-title-secrod">{item.title}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        )
                    }) : null
                }
            </Col>
        )
    } else {
        return null;
    }
}

export default RecommandArticles2;